portalThemeCtrl = (
  $scope
  currentUser
) ->
  "ngInject"

  if currentUser.theme?.portal_favicon_title
    links = document.querySelectorAll("link[rel*='icon']")
    links.forEach (link) ->
      link.parentNode.removeChild(link)
    link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'icon'
    link.href = currentUser.theme?.portal_favicon_url
    document.getElementsByTagName('head')[0].appendChild(link)
    document.title = currentUser.theme?.portal_favicon_title

  vm = {
    current_user: currentUser
    css: ":root {--primary-color: #{current_user.theme?.portal_primary_color};}"
    showCustomColors: !!currentUser.theme?.portal_primary_color
  }

  $scope.vm = vm
  return

export default portalThemeCtrl
