import * as _ from "lodash"
import * as angular from "angular"

subjectsMyScheduleCtrl = (
  $q
  $scope
  $state
  currentUser
  SubjectInteractionSessionSvc
  ReservationSvc
  CompanySvc
  RecruitingCampaignSvc
  SubjectInteractionSvc
  SessionSvc
  Restangular
  moment
) ->
  "ngInject"


  promises = [
    CompanySvc.one(currentUser.company_id).get()
    SubjectInteractionSessionSvc.all().getList({subject_id: currentUser.id, start_date: moment.utc().subtract(2, 'days')})
    RecruitingCampaignSvc.all().customGET('still_schedulable_campaigns_for_subject', {subject_id: currentUser.id})
    Restangular.all('participants/pending').getList({subject_id: currentUser.id, order_by: 'events.start_time', order_dir: 'asc'})
  ]
  $q.all(promises).then (results) ->
    vm.company = results[0]
    vm.subject_interaction_sessions = results[1]
    vm.schedulable_campaigns = results[2]
    vm.pending_participations = results[3]
    res_ids = _.uniq(_.map(vm.subject_interaction_sessions, (sis) -> sis.session.reservation_id))
    campaign_ids = _.uniq(_.map(vm.subject_interaction_sessions, (sis) -> sis.subject_interaction.recruiting_campaign_id))


    # get sessions and reservations for schedulable campaigns
    _.each vm.schedulable_campaigns, (campaign) ->
      camp_promises = [
        ReservationSvc.all().getList({event_id: campaign.event_id})
        SessionSvc.all().getList({event_id: campaign.event_id, per: 'all'})
      ]
      $q.all(camp_promises).then (results) ->
        campaign.reservations = results[0]
        campaign.sessions = results[1]
        campaign.sessions_by_reservation = _.groupBy(campaign.sessions, (session) -> session.reservation_id)
        _.each campaign.sessions, (session) ->
          session.is_full = session.fulfilled >= session.seats
          session.is_past = moment().isAfter(session.end_time)

    next_promises = [
      ReservationSvc.lookup().getList({'ids[]': res_ids})
      RecruitingCampaignSvc.lookup().getList({'ids[]': campaign_ids})
    ]

    $q.all(next_promises).then (results) ->
      reservations = results[0]
      campaigns = results[1]

      _.each vm.subject_interaction_sessions, (sis) ->
        sis.session.reservation = _.find(reservations, {id: sis.session.reservation_id})
        sis.subject_interaction.recruiting_campaign = _.find(campaigns, {id: sis.subject_interaction.recruiting_campaign_id})


  goToScheduler = (sis) ->
    cmd = { recruiting_campaign_id: sis.subject_interaction.recruiting_campaign_id, subject_id: currentUser.id }
    SubjectInteractionSvc.all().customPOST(cmd, 'subject_screen').then (result) ->
      $state.go('qualify.schedule', {recruiting_campaign_id: sis.subject_interaction.recruiting_campaign_id, subject_interaction_id: result.id, return_state: $state.current.name})

  schduleFromUnscheduled = (campaign) ->
    cmd = { recruiting_campaign_id: campaign.id, subject_id: currentUser.id }
    SubjectInteractionSvc.all().customPOST(cmd, 'subject_screen').then (result) ->
      $state.go('qualify.schedule', {recruiting_campaign_id: campaign.id, subject_interaction_id: result.id, return_state: $state.current.name})

  ################# For Display Purposes Only###############################
  areSessionsAvailable = (campaign) ->
    return if !campaign.reservations
    if Object.keys(campaign.reservations).length > 0
      true
    else
      false

  getUniqueDates = (campaign) ->
    return if !campaign.reservations
    reses = campaign.reservations
    _.each reses, (res) ->
    moment().tz(campaign.location_timezone_id)
    dates = _.map reses, (res) ->
      parts = moment(res.start).tz(campaign.location_timezone_id).toString().split(" ")
      return "#{parts[0]} #{parts[1]} #{parts[2]}"
    return _.uniq dates

  getSessionsForDate = (date, campaign) ->
    return [] if Object.keys(campaign.sessions_by_reservation).length < 1

    sesses = _.flatten _.map Object.keys(campaign.sessions_by_reservation), (key) -> campaign.sessions_by_reservation[key]
    _.select sesses, (sess) ->
      parts = moment(sess.start_time).tz(campaign.location_timezone_id).toString().split(" ")
      return "#{parts[0]} #{parts[1]} #{parts[2]}" == date
  ################# End For Display Purposes Only###############################


  vm =
    subject_interaction_sessions: []
    company: {}
    schedulable_campaigns: []
    goToScheduler: goToScheduler
    schduleFromUnscheduled: schduleFromUnscheduled
    getUniqueDates: getUniqueDates
    getSessionsForDate: getSessionsForDate
    areSessionsAvailable: areSessionsAvailable
    current_user: currentUser
  $scope.vm = vm

export default subjectsMyScheduleCtrl

