import * as _ from "lodash"
import * as angular from "angular"

onboardingSurveyCtrl = (
  $scope
  $stateParams
  $q
  $state
  $location
  $rootScope
  OnboardingConfigurationSvc
  Restangular
  surveyHelper
  languageHelper
  SubjectSvc
  currentUser
) ->
  "ngInject"


  data =
    onboarding_configuration_id: null
    scope_other_complete_question_count: null
    scope_total_question_count: null


  init = ->
    initialize_onboarding = () ->
      OnboardingConfigurationSvc.one(data.onboarding_configuration_id).customPOST({ demographic_category_id: $stateParams.demographic_category_id }, 'in_progress_check').then (result) ->
        #find what languages are available
        ip_data = result.plain()
        first_with_text = _.find(ip_data.questionnaire.data['pages'][0]['questions'], (q) -> q.text && Object.keys(q.text).length > 0)
        vm.locales = Object.keys(first_with_text['text'])
        search_locale = $location.search()['locale']
        if search_locale && _.any(vm.locales, search_locale)
          ip_data.locale = $location.search()['locale']
        else if _.contains vm.locales, currentUser.preferred_language
          ip_data.locale = currentUser.preferred_language
        else
          ip_data.locale = _.first(vm.locales)
        surveyHelper.populateFromServer(ip_data)
        surveyHelper.isBuilderPreview = false
        surveyHelper.addStyles(surveyHelper.questionnaire.config) if surveyHelper.questionnaire.config
        if result.complete
          surveyHelper.allow_serving_retake = true
        $rootScope.$broadcast "reloadTakerEvent", 0
        $scope.show_taker = true
        data.scope_other_complete_question_count = result.scope_other_complete_question_count
        data.scope_total_question_count = result.scope_total_question_count
        vm.scope_name = result.scope_name
        calculateProgress()
        return
      return

    if $stateParams.onboarding_configuration_id
      data.onboarding_configuration_id = $stateParams.onboarding_configuration_id
      initialize_onboarding()
    else
      Restangular.all('subjects').customGET('next_onboarding', { demographic_category_id: $stateParams.demographic_category_id, current_onboarding_configuration_id: $stateParams.onboarding_configuration_id }).then (result) ->
        if result
          data.onboarding_configuration_id = result.id
          initialize_onboarding()
        else
          if $stateParams.demographic_category_id
            $state.go('onboarding', $stateParams)
          else
            $state.go('onboarding_thank_you', $stateParams)
        return
      return
    return


  setLocale = (locale) ->
    await languageHelper.load(locale)
    $location.search(_.extend($location.search(), {locale: locale}))
    surveyHelper.locale = locale

  calculateProgress = ->
    questions = _.flatten _.map(surveyHelper.in_progress_questionnaire.pages, (page) -> page.questions)
    local_cnt = _.select(questions, (q) -> _.any(q.selected_answers)).length
    res = (data.scope_other_complete_question_count + local_cnt) / data.scope_total_question_count * 100
    res = 100 if res > 100
    vm.progress_decimal = Math.round(res) / 100

  $scope.$on "answerSelectedEvent", (event, data) ->
    Restangular.all("answer_submit_events").post({question_data: data})

  $scope.$on "questionnaireCompleteEvent", (event, questionnaire_data) ->
    demographic_ids = _.flatten _.map(questionnaire_data.demographic_categories, (demographic_ids, dc_id) -> _.map(demographic_ids, (d) -> parseInt(d)))
    cmd = {questionnaire_data: questionnaire_data.in_progress_questionnaire, demographic_ids: demographic_ids, demographic_category_id: $stateParams.demographic_category_id}
    OnboardingConfigurationSvc.one(data.onboarding_configuration_id).customPOST(cmd, 'questionnaire_submit').then (result) ->
      if result.next_onboarding_configuration_id
        $state.go($state.current, _.extend($stateParams, { onboarding_configuration_id: result.next_onboarding_configuration_id }), {reload: true})
      else if $stateParams.pass_through_sref
        $state.go($stateParams.pass_through_sref, $stateParams)
      else
        $state.go('onboarding_thank_you', $stateParams)

  next_page_check = $rootScope.$on "questionnaireNextPageEvent", (event, questionnaire_data) ->
    calculateProgress()
    return

  $scope.$on('$destroy',(event) ->
    next_page_check()
    return
  )

  vm =
    locales: []
    scope_name: null
    progress_decimal: null
    surveyHelper: surveyHelper
    setLocale: setLocale
    languageHelper: languageHelper

  $scope.vm = vm

  init()

export default onboardingSurveyCtrl

