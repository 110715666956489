import * as _ from "lodash"
import * as angular from "angular"

onlineConfirmationCtrl = (
  $scope
  $q
  $stateParams
  $state
  $location
  $filter
  ngToast
  SubjectInteractionSvc
  RecruitingCampaignSvc
  SensoryTestSvc
  CompanySvc
  currentUser
  languageHelper
  moment
  appInfo
) ->
  "ngInject"


  init = ->
    promises = [
      SubjectInteractionSvc.one($stateParams.subject_interaction_id).get()
      RecruitingCampaignSvc.one($stateParams.recruiting_campaign_id).get()
      CompanySvc.one(currentUser.company_id).get()
      RecruitingCampaignSvc.meta($stateParams.recruiting_campaign_id)
    ]
    $q.all(promises).then (responses) ->
      vm.interaction = responses[0]
      vm.campaign = responses[1]
      vm.company = responses[2]
      vm.campaign.meta = responses[3]

      next_promises = []
      next_promises.push(SensoryTestSvc.meta(vm.campaign.sensory_test_id))
      $q.all(next_promises).then (responses) ->

        temp = compactAndMerge(angular.copy(languageHelper), vm.company.meta.defaults.recruiting.scripts)
        vm.scripts = compactAndMerge(temp, vm.campaign.meta.scripts)

        vm.variables =
          subject_name: "#{vm.interaction.subject.first_name} #{vm.interaction.subject.last_name}"
          company_name: vm.company.name
          event_start_date: $filter('preferredDateTime')(moment(vm.campaign.event_start_date).tz(vm.campaign.location_timezone_id), vm.campaign)
          event_end_date: $filter('preferredDateTime')(moment(vm.campaign.event_end_date).tz(vm.campaign.location_timezone_id), vm.campaign)
          event_title: vm.campaign.event_name || vm.campaign.event.title
          address: vm.campaign.location_address || '{no address provided}'
          location_city: if vm.campaign then vm.campaign.location_city + " " + vm.campaign.location_state else '{no location provided}'
          location_name: vm.campaign.location_name || '{no location name provided}'
          phone_number: vm.company.meta.defaults.recruiting.phone_number || '{no number provided}'
          compensation: vm.campaign.event_compensation || '{no compensation amount provided}'
          recruiter_name: currentUser.full_name

        vm.locales = responses[0].locales
        checkPreferredLanguage()
        setLocale($location.search()['locale'] || vm.locales[0])


  checkPreferredLanguage = () ->
    if vm.interaction.subject.preferred_language && _.contains(vm.locales, vm.interaction.subject.preferred_language)
      setLocale(vm.interaction.subject.preferred_language)
    else
      setLocale($location.search()['locale'] || vm.locales[0])

  setLocale = (locale) ->
    $location.search(_.extend($location.search(), {locale: locale}))
    vm.current_locale = if Array.isArray(locale) then locale[0] else locale

  submit = ->
    return if vm.interaction.outcome == 'no_outcome'
    vm.interaction.save().then (response) ->
      if response.redirect_url
        window.location = response.redirect_url
      else if $stateParams.return_state
        $state.go($stateParams.return_state, _.extend($stateParams, subject_id: vm.interaction.subject.id))
      else
        if vm.campaign.checkin_type == 'checkin'
          $state.go('qualify.schedule_confirmation', $stateParams)
        else
          # Stay here

    , (response) ->
      vm.interaction.messages = response.data.messages
      vm.interaction.outcome = 'no_outcome'

  vm = {
    show_successful_scheduled_message: false
    languageHelper: languageHelper
    campaign: {}
    interaction: {}
    outcome: null
    reservations: {}
    show_clear_schedule_message: false
    selected_reservation_group: null
    subject: null
    current_locale: 'en_US'
    setLocale: setLocale
    submit: submit
    appInfo: appInfo
    current_user: currentUser
  }
  $scope.vm = vm
  init()

export default onlineConfirmationCtrl
