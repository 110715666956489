import * as angular from "angular"

directives = angular.module('redjade.portal.directives', [

])
  .directive('portalTheme', require('./portalTheme.directive.coffee').default)
  .directive('portalThemeLogo', require('./portalThemeLogo.directive.ts').default)
  .directive('portalThemeSidebar', require('./portalThemeSidebar.directive.ts').default)
  .directive('portalThemeFooter', require('./portalThemeFooter.directive.ts').default)
  .directive('portalTopNav', require("./portalTopNav.directive").default)
  .controller('portalTopNavCtrl', require("./portalTopNav.controller").default)
  .controller('SessionExpirationWarningCtrl', require("./session_expiration_warning.controller").default)
  .controller('portalThemeCtrl', require('./portalTheme.controller').default)
  .run(($templateCache) ->
    "ngInject"
    $templateCache.put('directives/portal_top_nav.html', require('./portal_top_nav.html'))
    $templateCache.put('directives/portal_theme.html', require('./portal_theme.html'))
    $templateCache.put('rj_shared/session_expiration_warning.html', require("./session_expiration_warning.html"))
  )


export default directives
