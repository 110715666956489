import * as angular from "angular"

import custom from './custom/index'
import shared from './shared/index'
stylesheets = angular.module('redjade.stylesheets', [
  custom
  shared
])


export default stylesheets
