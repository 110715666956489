import * as angular from "angular"
import cookies from "~angular/src/rj_shared/utils/cookies"
import urls from "~angular/src/rj_shared/utils/urls"
initializers = require("~angular/src/startup/initializers.ts").default

boot = (options) ->
  angular.element(document).ready ->
    ngInjector = angular.injector(['ng'])
    $http = ngInjector.get('$http')
    $q = ngInjector.get('$q')
    req =
      method: 'GET'
      headers:
        'Content-Type': 'application/json'
        'Authorization': 'Bearer ' + cookies.getCookie('portal_auth')
      withCredentials: true

    promises = [
      $http.get('/api/v1/subjects/me', req)
      $http.get("/api/v1/companies/portal_theme?hostname=#{window.location.host}", req)
    ]
    $q.all(promises).then (responses) ->
      window.current_user = responses[0].data.response #yes I have to do this for the freakin translate service to work in the controllers

      await initializers.languageHelper(angular)
      app = angular.bootstrap(document, [options.app_module_name])
      currentUser = app.get('currentUser')
      angular.extend(currentUser, responses[0].data.response)

      return
    , (response) ->
      if response.status == 401 || response.status <= 0 # shitty IE bug where the status code is wrong
        window.location = "//#{window.location.hostname}/login" + '?return_url=' + window.location.href unless window.navigating
      return

export default boot
