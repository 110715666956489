portalTheme = (
) ->
  "ngInject"

  return {
    restrict: 'E',
    replace: true,
    controller: 'portalThemeCtrl',
    templateUrl: 'directives/portal_theme.html'
  }

export default portalTheme
