import * as angular from "angular"


campaigns = angular.module('redjade.portal.ui.campaigns', [

])
  .controller('campaignsCalendarCtrl', require("./campaignsCalendar.controller").default)
  .controller('campaignsListCtrl', require("./campaignsList.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/campaigns/calendar.html', require("./calendar.html"))
    $templateCache.put('ui/campaigns/list.html', require("./list.html"))
    $templateCache.put('ui/campaigns/screener.html', require("./screener.html"))
  )




export default campaigns
