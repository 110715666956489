import * as _ from "lodash"
import * as angular from "angular"

scheduleCtrl = (
  $q
  $scope
  $state
  $location
  $stateParams
  $filter
  currentUser
  languageHelper
  CompanySvc
  SessionSvc
  ReservationSvc
  SensoryTestSvc
  SubjectInteractionSvc
  RecruitingCampaignSvc
  SubjectInteractionSessionSvc
  messageHelper
  campaignSessionSvc
  moment
  appInfo
) ->
  "ngInject"

  previous_sessions = []
  previous_interactions_sessions = []
  init = ->
    vm.loading = true
    promises = [
      SubjectInteractionSvc.one($stateParams.subject_interaction_id).get()
      RecruitingCampaignSvc.one($stateParams.recruiting_campaign_id).get()
      SessionSvc.all().getList({subject_interaction_id: $stateParams.subject_interaction_id, per: 'all', future_only: true})
      SubjectInteractionSessionSvc.all().getList({subject_id: currentUser.id, per: 'all'})
      CompanySvc.one(currentUser.company_id).get()
      RecruitingCampaignSvc.meta($stateParams.recruiting_campaign_id)
    ]
    $q.all(promises).then (responses) ->
      vm.interaction = responses[0]
      vm.interaction.subject_interaction_sessions = []
      vm.campaign = responses[1]
      vm.sessions = responses[2]
      previous_interactions_sessions = responses[3]
      vm.company = responses[4]
      vm.campaign.meta = responses[5]

      next_promises = []
      next_promises.push(ReservationSvc.lookup().getList('ids[]': _.uniq(_.map(vm.sessions, 'reservation_id'))))
      next_promises.push(SensoryTestSvc.meta(vm.campaign.sensory_test_id))
      $q.all(next_promises).then (responses) ->
        vm.reservations = {}
        reservations_by_group = _.groupBy(responses[0], (reservation) -> reservation.group_number)

        # mark sessions without any seats available as full
        _.each vm.sessions, (session) ->
          session.is_full = session.fulfilled >= session.seats
          return true

        # kick out any reservations that don't have at least one session with seats available
        _.each Object.keys(reservations_by_group), (group_number) ->
          reservations_by_group[group_number] = _.select(reservations_by_group[group_number], (r) -> _.any(vm.sessions, (s) -> !s.is_full && s.reservation_id == r.id))

        # kick out any reservations that do not have enough other reservations to fulfill the required_session_count
        _.each Object.keys(reservations_by_group), (group_number) ->
          _.each reservations_by_group[group_number], (reservation) ->
            if reservations_by_group[reservation.group_number].length >= vm.campaign.required_session_count #&& _.find(reservations_by_group[reservation.group_number], {id: reservation.id})
              vm.reservations[reservation.id] = reservation


        # order the remaining reservations and sessions for display in the view
        reservation_ids = _(reservations_by_group).map((reservations, group_number) -> reservations).flatten().map('id').value()

        vm.interaction.subject_interaction_sessions = _(previous_interactions_sessions).select((sis) ->
          session = _.find(vm.sessions, {id: sis.session_id})
          sis.subject_interaction.recruiting_campaign_id == vm.campaign.id && !session.is_full && vm.reservations[session.reservation_id]
        ).map((sis) ->
          sis = sis.plain()
          if sis.subject_interaction_id != vm.interaction.id
            sis.subject_interaction_id = vm.interaction.id
            delete sis.id
          sis
        ).value()


        vm.sessions = _.select(vm.sessions, (session) -> _.contains(reservation_ids, session.reservation_id))
        vm.sessions_by_reservation = _.groupBy(vm.sessions, (session) -> session.reservation_id)
        vm.ordered_reservation_keys = orderedSessionGroups(vm.sessions_by_reservation)

        temp = compactAndMerge(angular.copy(languageHelper), vm.company.meta.defaults.recruiting.scripts)
        vm.scripts = compactAndMerge(temp, vm.campaign.meta.scripts)

        vm.variables =
          subject_name: "#{vm.interaction.subject.first_name} #{vm.interaction.subject.last_name}"
          #company_name: vm.company.name
          event_start_date: moment(vm.campaign.event_start_date).format('MMMM Do YYYY')
          event_end_date: moment(vm.campaign.event_end_date).format('MMMM Do YYYY')
          event_title: vm.campaign.event_name
          address: vm.campaign.address || '{no address provided}'
          location_city: vm.campaign.location_city || '{no location city provided}'
          location_name: vm.campaign.location_name || '{no location name provided}'
          compensation: parseFloat(vm.campaign.event_compensation ).toFixed(2) || '{no compensation amount provided}'
          required_session_count: vm.campaign.required_session_count
          recruiter_name: if vm.interaction.user then vm.interaction.user.full_name else 'None'
          session_date: if vm.sessions[0] then $filter('preferredDate')(moment(vm.sessions[0].start_time).tz(vm.campaign.location_timezone_id), vm.campaign) else ''
          session_date: if vm.sessions[0] then $filter('preferredDateTime')(moment(vm.sessions[0].start_time).tz(vm.campaign.location_timezone_id), vm.campaign) else ''
        vm.locales = responses[1].locales
        checkPreferredLanguage()

        if previous_sessions.length > 0
          _.each previous_sessions, (ps) ->
            if _.find(vm.sessions, (sess) -> sess.id == ps.session_id)
              vm.interaction.subject_interaction_sessions.push(ps)

        reservations = _.map Object.keys(vm.reservations), (key) -> vm.reservations[key]
        sessions = _.flatten _.map Object.keys(vm.sessions_by_reservation), (key) -> vm.sessions_by_reservation[key]
        vm.unique_dates = campaignSessionSvc.getUniqueDatesWithSessions(vm.campaign.location_timezone_id, reservations, sessions)

        previous_sessions = []
        vm.loading = false


  checkPreferredLanguage = () ->
    if $location.search()['locale'] == undefined && vm.interaction.subject.preferred_language && _.contains(vm.locales, vm.interaction.subject.preferred_language)
      setLocale(vm.interaction.subject.preferred_language)
    else
      setLocale($location.search()['locale'] || vm.locales[0])

  setLocale = (locale) ->
    $location.search(_.extend($location.search(), {locale: locale}))
    vm.current_locale = if Array.isArray(locale) then locale[0] else locale

  findSession = (id) ->
    return _.find(vm.sessions, {id: id})

  orderedSessionGroups = (obj) ->
    ordered_keys = Object.keys(obj)
    ordered_keys.sort((a,b) ->
      return obj[a][0].start_time > obj[b][0].start_time)
    ordered_keys

  sessionLabel = (session) ->
    sis = _.find(vm.interaction.subject_interaction_sessions, (sis) -> sis.session_id == session.id)
    if sis
      'Scheduled'
    else
      null

  clickSession = (session) ->
    if(vm.isSessionDisabled(session))
      return
    vm.interaction.subject_interaction_sessions = [] unless vm.interaction.subject_interaction_sessions
    existing = _.filter(vm.interaction.subject_interaction_sessions, {session_id: session.id})
    _.each existing, (s) ->
      vm.interaction.subject_interaction_sessions = _.without vm.interaction.subject_interaction_sessions, s
    if existing.length == 0
      vm.interaction.subject_interaction_sessions.push({session_id: session.id, subject_interaction_id: vm.interaction.id})

  areSessionsAvailable = ->
    if _.any(vm.sessions, (sess) -> sess.is_full != true) && Object.keys(vm.reservations).length > 0
      true
    else
      false

  fulfilledReservationIds = ->
    selected_session_ids = _.map vm.interaction.subject_interaction_sessions, 'session_id'
    reservation_ids = []
    _.each selected_session_ids, (session_id) ->
      session = _.find(vm.sessions, (session) -> session_id == session.id)
      reservation_ids.push(session.reservation_id) if session
    return reservation_ids

  selectedReservationGroup = ->
    if vm.interaction.subject_interaction_sessions
      if vm.interaction.subject_interaction_sessions.length > 0 && vm.reservations && vm.reservations[findSession(vm.interaction.subject_interaction_sessions[0].session_id).reservation_id]
        vm.reservations[findSession(vm.interaction.subject_interaction_sessions[0].session_id).reservation_id].group_number
      else
        null

  isSessionInPast = (session) ->
    moment().isAfter(session.end_time)

  isSessionDisabled = (session) ->
    if _.find(vm.interaction.subject_interaction_sessions, (sis) -> sis.session_id == session.id)
      return false
    else if isSessionInPast(session)
      return true
    else if !session.schedulable
      return true
    else if _.contains(fulfilledReservationIds(), session.reservation_id) #&& subject_interaction_sessions.length != vm.campaign.required_session_count
      return true
    else if vm.interaction.subject_interaction_sessions.length == vm.campaign.required_session_count
      return true
    else
      return false

  showSubmit = ->
    vm.interaction && vm.interaction.outcome == 'no_outcome' && areSessionsAvailable()

  scheduleConflictClicked = ->
    if _.any(vm.interaction.subject_interaction_sessions)
      vm.show_clear_schedule_message = true
    else
      vm.show_clear_schedule_message = false
      submit()

  submit = () ->
    if _.any(vm.interaction.subject_interaction_sessions)
      vm.interaction.outcome = 'scheduled'
    else
      vm.interaction.outcome = 'schedule_conflict'
    vm.interaction.save().then (response) ->
      if response.redirect_url
        window.location = response.redirect_url
      else if vm.interaction.outcome == 'schedule_conflict'
        #stay here
      else
        $state.go('qualify.schedule_confirmation', $stateParams)
    , (response) ->
      vm.error = messageHelper.firstMessage(response.data.messages)
      vm.session_rejected = true
      vm.interaction.messages = response.messages
      previous_sessions = angular.copy(vm.interaction.subject_interaction_sessions)
      init()

  vm = {
    loading: true
    campaign: null
    interaction: {subject_interaction_sessions:[]}
    outcome: null
    sessions: []
    error: null
    areSessionsAvailable: areSessionsAvailable
    ordered_reservation_keys: []
    reservations: {}
    subject_interaction_sessions: []
    show_clear_schedule_message: false
    selected_reservation_group: null
    subject: null
    current_locale: 'en_US'
    scheduleConflictClicked: scheduleConflictClicked
    sessionLabel: sessionLabel
    clickSession: clickSession
    isSessionInPast: isSessionInPast
    isSessionDisabled: isSessionDisabled
    selectedReservationGroup: selectedReservationGroup
    showSubmit: showSubmit
    submit: submit
    unique_dates: []
    appInfo: appInfo
    current_user: currentUser
  }
  $scope.vm = vm

  init()

export default scheduleCtrl
