var portalThemeSidebar

portalThemeSidebar = function () {
  "ngInject"
  return {
    restrict: "E",
    replace: true,
    scope: {},
    controller: [
      "$scope",
      "currentUser",
      "$sce",
      function ($scope, currentUser, $sce) {
        $scope.trustedTemplate = $sce.trustAsHtml(currentUser.theme?.portal_sidebar_template)

        //   $scope.trustedTemplate = $sce.trustAsHtml(`
        //   <section class="sidebar-links">
        //   <label class="sidebar-link-title"> Information: </label>
        //   <ul>
        //   <li>
        //   <a class="btn btn-link" href="/faq"><span>FAQ</span></a>
        //   </li>
        //   <li>
        //   <a class="btn btn-link" href="/parents_information"><span>Parent Information</span></a>
        //   </li>
        //   <li>
        //   <a class="btn btn-link" href="/program_details"><span>Program Details</span></a>
        //   </li>
        //   <li>
        //   <a class="btn btn-link" href="/local_program"><span>Local Program</span></a>
        //   </li>
        //   <li>
        //   <a class="btn btn-link" href="/refer_a_friend" class="btn btn-primary">Refer A Friend</a>
        //   </li>
        //   </ul>

        //   <a class="contact-btn btn btn-primary" href="/contact_us" class="btn btn-primary">Contact Us</a>
        //   </section>

        //   <style>
        //   .sidebar-links{
        //     margin: 0 15px;
        //   }
        //   .sidebar-links .sidebar-link-title{
        //     color: #808080;
        //   }
        //   .sidebar-links ul{
        //   list-style-type: none;
        //   padding: 0;
        //   margin: 0 5px 0 0;
        //   }
        //   .sidebar-links .contact-btn{
        //     margin-top: 15px;
        //   }
        //   </style>
        // `);
      },
    ],
    template: '<div class="portal-theme-sidebar" ng-bind-html="trustedTemplate"></div>',
  }
}

export default portalThemeSidebar
