import * as angular from "angular"


nav = angular.module('redjade.portal.ui.nav', [

])
  .controller('mainNavCtrl', require("./mainNav.controller").default)
  .controller('miniSideBarCtrl', require("./miniSideBar.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/nav/main_nav.html', require("./main_nav.html"))
    $templateCache.put('ui/nav/mini_side_bar.html', require("./mini_side_bar.html"))
  )




export default nav
