import * as _ from "lodash"
import cookies from "~angular/src/rj_shared/utils/cookies"

portalTopNavCtrl = (
  $scope
  currentUser
  appInfo
  HouseholdSvc
  Restangular
  $window
  $rootScope
  $interval
  SubjectSvc
  ngToast
  appStateSvc
  $uibModal
  $timeout
) ->
  "ngInject"

  toggleSidebar = ->
    appStateSvc.setSidebarToggled !appStateSvc.isSidebarToggled()

  profileMenuOpened = ->
    return if ptn_vm.household
    unless currentUser.head_of_household
      ptn_vm.household = { subjects: [] }
      ptn_vm.loading_household = false
      return
    cmd = { subject_id: if currentUser.impersonator then currentUser.impersonator.id else currentUser.id }
    HouseholdSvc.all().customGET('household_for_subject', cmd).then (result) ->
      ptn_vm.household = result
      ptn_vm.household.subjects = _.select(ptn_vm.household.subjects, (s) ->
        !s.archived_at && s.is_minor && s.id != currentUser.impersonator?.id && s.id != currentUser.id && !s.head_of_household
      )
      ptn_vm.loading_household = false
      return
    return

  impersonate = (subject) ->
    SubjectSvc.one(subject.id).customPOST(null, "impersonate", {}, {}).then((result) ->
      domain = '.' + $window.location.host
      document.cookie = "portal_auth=#{result};path=/;domain=.#{window.location.host};"
      $window.location.reload()
      return
    ,(error) ->
      ngToast.danger content: "Error impersonating user"
      return
    )
    return

  initCustomLogo = ->
    if current_user.theme && current_user.theme?.portal_logo_template
      return true
    return false

  ping_timeout = null #check if the user session has expired
  WARN_TIME = 120000
  warning_open = false
  ping_interval_check = -> #log out if the user session is expired, warn 2 minutes early
    internal_callback = ->
      Restangular.all("auth").customGET("ping").then( (response) ->
        expires_in_millis = new Date(response.expires_at).getTime() - new Date().getTime()
        if(!warning_open && expires_in_millis <= WARN_TIME) # warn 2 minutes early
          warning_open = true
          $uibModal.open(
            templateUrl: "rj_shared/session_expiration_warning.html"
            controller: 'SessionExpirationWarningCtrl'
          ).closed.then(->
            warning_open = false
            Restangular.all("auth/keep_alive").customPUT()
          )
        recheck_interval = Math.max(expires_in_millis - WARN_TIME, WARN_TIME/2)
        ping_timeout = $timeout(internal_callback, recheck_interval)
      )
    internal_callback()
  ping_interval_check()


  ptn_vm = {
    current_user: currentUser
    appInfo: appInfo
    protocol: $window.location.protocol
    appStateSvc: appStateSvc
    household: null
    loading_household: true
    showCustomLogo: initCustomLogo()
    toggleSidebar: toggleSidebar
    profileMenuOpened: profileMenuOpened
    impersonate: impersonate
  }
  $scope.ptn_vm = ptn_vm
  return

export default portalTopNavCtrl
