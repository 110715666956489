import * as _ from "lodash"

onboardingCtrl = (
  $q
  $scope
  DemographicCategorySvc
  OnboardingConfigurationSvc
  CompanySvc
  SubjectSvc
  currentUser
  $state
) ->
  "ngInject"


  if currentUser.progress.interest_selection_necessary
    $state.go('onboarding.interest_selection', { pass_through_sref: $state.current.name })

  $q.all([
    OnboardingConfigurationSvc.all().getList(subject_id: currentUser.id)
    DemographicCategorySvc.onboarding().getList(subject_id: currentUser.id)
    CompanySvc.one(currentUser.company_id).customGET("settings")
    SubjectSvc.one(currentUser.id).customGET("subject_interests")
    SubjectSvc.one(currentUser.id).customGET("subject_interests/progress")
  ]).then (results) ->
    vm.onboardings = _.reject(results[0], (ob) -> _.any(ob.demographic_categories))
    vm.categories = _.select(results[1], (cat) -> _.any(results[3], (interest) -> interest.id == cat.id))
    vm.allow_retake = results[2].meta.defaults.recruiting.allow_onboarding_retake
    vm.progress = results[4].progress
    return

  vm = {
    onboardings: []
    categories: []
    allow_retake: false
  }
  $scope.vm = vm

export default onboardingCtrl

