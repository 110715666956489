// @ts-ignore
window.toPromise = <T>(data: T) : Promise<T> => {
    return new Promise(resolve => resolve(data) )
}

export function formatDisplayTime(seconds, hideZeros) {
    seconds = parseInt(seconds)
    if (isNaN(seconds)) {
        return ""
    }
    const pad = (n) => n.toString().padStart(2, '0');
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    // hide hours/minutes when they are zero
    if (hideZeros) {
    let time = ""
        if (hours) {
            time += `${pad(hours)}:`
        }
        if (minutes) {
            time += `${pad(minutes)}:`
        }
        time += `${pad(secs)}`
        return time
    }
    // else, display as HH:MM:SS, but always display at least 2 digits for each
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
}

