import * as angular from "angular"

# SASS
import './colors.sass'
import './interests.sass'
import './nav.sass'

custom = angular.module('redjade.stylesheets.custom', [

])





export default custom
