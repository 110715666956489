onboardingThankYouCtrl = (
  $scope
  $stateParams
  $q
  $state
  currentUser
) ->
  "ngInject"



  vm =
    locales: []
    current_locale: currentUser.preferred_language || 'en_US'

  $scope.vm = vm

export default onboardingThankYouCtrl

