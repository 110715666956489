import * as _ from "lodash"
import cookies from "~angular/src/rj_shared/utils/cookies"

subjectProfilePicCtrl = (
  $scope
  currentUser
  FileUploader
  appInfo
  ngToast
) ->
  "ngInject"


  $scope.subject = currentUser


  initProfilePicUploader = ->
    #$scope.can_upload_profile_pic = currentUser.red_jade_admin || user_id == currentUser.id

    uploader = $scope.uploader = new FileUploader({
      url: "#{appInfo.api_url}/subjects/#{$scope.subject.id}/update_profile_picture",
      withCredentials: true
      headers:
        'Authorization': 'Bearer '+ cookies.getCookie('portal_auth')
    })

    uploader.onAfterAddingFile = (fileItem) ->
      if !_.contains(["image/png", "image/jpg", "image/jpeg"], fileItem.file.type)
        ngToast.danger content: "Not a valid file type"
        this.queue = []
        return false
      else if (fileItem.file.size > 100000)
        ngToast.danger content: "File size is too large"
        this.queue = []
        return false
      else
        fileItem.upload()

    uploader.onCompleteItem = (fileItem, result, status, headers) ->
      $scope.subject.medium_profile_picture = result.response.medium_profile_picture
      uploader.queue = []
      return


  initProfilePicUploader()

export default subjectProfilePicCtrl
