mainNavCtrl = (
  $scope
  appStateSvc
  currentUser
) ->
  "ngInject"


  vm = {
    current_user: currentUser
    appStateSvc: appStateSvc
  }
  $scope.vm = vm

export default mainNavCtrl

