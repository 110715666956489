import * as angular from "angular"


qualify = angular.module('redjade.portal.ui.qualify', [

])
  .controller('onlineConfirmationCtrl', require("./onlineConfirmation.controller").default)
  .controller('scheduleConfirmationCtrl', require("./scheduleConfirmation.controller").default)
  .controller('scheduleCtrl', require("./schedule.controller").default)
  .controller('screenedCtrl', require("./screened.controller").default)
  .controller('screenerCtrl', require("./screener.controller").default)
  .controller('terminateCtrl', require("./terminate.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/qualify/terminate.html', require("./terminate.html"))
    $templateCache.put('ui/qualify/online_confirmation.html', require("./online_confirmation.html"))
    $templateCache.put('ui/qualify/schedule.html', require("./schedule.html"))
    $templateCache.put('ui/qualify/schedule_confirmation.html', require("./schedule_confirmation.html"))
    $templateCache.put('ui/qualify/screened.html', require("./screened.html"))
    $templateCache.put('ui/qualify/screener.html', require("./screener.html"))
  )




export default qualify
