import * as _ from "lodash"
import * as angular from "angular"

minorEntryCtrl = (
  $scope
  $rootScope
  $uibModal
  Restangular
  currentUser
  LocationSvc
  SubjectSvc
  HouseholdSvc
  DemographicCategorySvc
  ngToast
  COUNTRIES
  languageHelper
  $translate
  $window
  $q
  SubjectInteractionSvc
  RecruitingCampaignSvc
  $state
  $stateParams
  CompanySvc
) ->
  "ngInject"


  MINOR_AGE = 18
  CompanySvc.one(currentUser.company_id).customGET("settings").then (res) ->
    MINOR_AGE = res.meta.defaults.recruiting.adult_age || 18

  current_subject = currentUser
  data = {
    org_minors: null
    hidden: {}
    all_rules: []
  }

  promises = [
    HouseholdSvc.all().customGET('household_for_subject', { subject_id: current_subject.id })
    Restangular.all('subject_validation_rules').getList()
  ]

  $q.all(promises).then (results) ->
    # vm.minor_entry_form.$setDirty() if vm.minor_entry_form
    household = Restangular.restangularizeElement(null, results[0], 'households')
    separateSubjects(household.subjects)

    data.all_rules = results[1]
    buildRules()

    setFormStateFromRules()

    _.each Object.keys(languageHelper.languages), (k) ->
      lang =
        name: languageHelper.languages[k]
        code: "#{k}"
      vm.languages.push(lang)

    return

  buildRules = ->
    data.rules = _.select(data.all_rules, (rule) ->
      rule.target == 'minor'
    )
    vm.category_rules = _.select(data.rules, (r) -> r.rule_type == 'demographic_category_rule')
    vm.subjects_category_selections = buildSubjectCategorySelections(vm.minors)
    return

  submit = (form) ->
    vm.state.errors = {}
    minors = _.map(vm.minors, (m) ->
      m.demographic_ids = _.compact _.values(vm.subjects_category_selections[m.cid])
      m
    )
    $q.all(_.map(minors, (m) -> if m._destroy then m.remove() else m.save())).then (results) ->
      vm.minors = _.select(results, (r) -> !_.any(minors, (m) -> m.id == r.id && m._destroy))
      data.org_minors = _.map(vm.minors, (m) -> Restangular.copy(m))
      setFormStateFromRules()
      form.$setPristine()
      me = Restangular.restangularizeElement(null, currentUser, 'subjects')
      me.fromServer = true
      me.minor_entry_completed_at = new Date()
      me.save().then (result) ->
        if $stateParams.pass_through_sref
          $state.go($stateParams.pass_through_sref, _.extend($stateParams, {locale: currentUser.preferred_language}))
        else
          ngToast.success content: "Succesfully updated minors!"
        return
      return
    , (first_result) ->
      subject_cid = first_result.config.data.cid
      vm.state.errors[subject_cid] = first_result.data.messages
      subject = _.find(vm.minors, (m) -> m.cid == subject_cid)
      if !isMinor(subject) && _.any(Object.keys(first_result.data.messages), (k) -> k == 'first_name')
        vm.show_all_fields = true
      return
    return


  cancel = (form) ->
    vm.minors = _.map(data.org_minors, (m) -> Restangular.copy(m))
    buildRules() # reset demographics
    vm.state.errors = _.reduce(vm.minors, (subject_object, subject) ->
      subject_object[subject.cid] = null
      subject_object
    , {})
    form.$setPristine()

  separateSubjects = (subjects) ->
    vm.adults = _(subjects).select((s) -> !isMinor(s, false)).concat(vm.adults).sortBy((s) -> s.first_name).value()
    vm.minors = _(subjects).select((s) -> !s.archived_at && isMinor(s, false)).map((s) -> rs = Restangular.restangularizeElement(null, s, 'subjects'); rs.fromServer = true; rs.cid = guid(); rs).sortBy((s) -> s.date_of_birth).value()
    data.org_minors = _.map(vm.minors, (m) -> Restangular.copy(m))
    return

  showFields = (subject, fields) ->
    _.any(fields, (field) -> (vm.show_all_fields || vm.minor_rules[field]) && !data.hidden[subject.cid][field])

  isMinor = (subject, default_to_minor = true) ->
    return true if !subject.date_of_birth && default_to_minor
    moment(subject.date_of_birth).isAfter(moment().subtract(MINOR_AGE, 'years'))

  showOptional = (subject, field) ->
    if isMinor(subject)
      return false if _.includes(['code', 'date_of_birth'], field)
    else
      return false if _.includes(['code', 'first_name', 'last_name'], field)
    !vm.read_only[subject.cid][field] && !_.any(data.rules, (rule) ->
      rule.selected_attribute == field && (rule.validation_type == 'required' || rule.validation_type == 'required_and_unique')
    )

  setFormStateFromRules = ->
    vm.minor_rules = _(data.rules).select((rule) -> rule.target == 'minor' && _.includes(rule.validation_type, 'required')).reduce((rule_object, rule) ->
      if rule.rule_type == 'subject_field_rule'
        rule_object[rule.selected_attribute] = true
      else if rule.rule_type == 'demographic_category_rule'
        rule_object['category_' + rule.demographic_category.id] = true
      rule_object
    , { date_of_birth: true })
    vm.read_only = buildReadOnlyFormState(vm.minors)
    data.hidden = buildHiddenFormState(vm.minors)
    return

  buildReadOnlyFormState = (subjects) ->
    _.reduce(subjects, (subject_object, subject) ->
      read_only_rules = _(data.rules).select((rule) -> rule.target == 'minor').select((rule) ->
        rule.visibility == 'read_only' || (
          rule.after_collection == 'then_read_only' && (
            rule.rule_type == 'subject_field_rule' &&
            subject[rule.selected_attribute]
          ) || (
            rule.rule_type == 'demographic_category_rule' &&
            rule.after_collection == 'then_read_only' &&
            _.any(subject.demographic_ids, (demo_id) -> _.any(rule.demographic_category.demographics, (d) -> d.id == demo_id))
          )
        )
      ).value()
      subject_object[subject.cid] = _.reduce(read_only_rules, (state_object, rule) ->
        if rule.rule_type == 'subject_field_rule'
          state_object[rule.selected_attribute] = true
        else if rule.rule_type == 'demographic_category_rule'
          state_object["category_#{rule.demographic_category.id}"] = true
        state_object
      , {})
      subject_object
    , {})

  buildHiddenFormState = (subjects) ->
    _.reduce(subjects, (subject_object, subject) ->
      hiding_rules = _(data.rules).select((rule) -> rule.target == 'minor').select((rule) ->
        rule.visibility == 'hidden' || (
          rule.after_collection == 'then_hidden' && (
            rule.rule_type == 'subject_field_rule' &&
            subject[rule.selected_attribute]
          ) || (
            rule.rule_type == 'demographic_category_rule' &&
            rule.after_collection == 'then_hidden' &&
            _.any(subject.demographic_ids, (demo_id) -> _.any(rule.demographic_category.demographics, (d) -> d.id == demo_id))
          )
        )
      ).value()
      subject_object[subject.cid] = _.reduce(hiding_rules, (state_object, rule) ->
        if rule.rule_type == 'subject_field_rule'
          state_object[rule.selected_attribute] = true
        else if rule.rule_type == 'demographic_category_rule'
          state_object["category_#{rule.demographic_category.id}"] = true
        state_object
      , {})
      subject_object
    , {})

  buildSubjectCategorySelections = (subjects) ->
    _.reduce(subjects, (subject_object, subject) ->
      subject_object[subject.cid] = _.reduce(vm.category_rules, (obj, cat_rule) ->
        obj[cat_rule.demographic_category.id] = _.find(subject.demographic_ids, (demo_id) -> _.any(cat_rule.demographic_category.demographics, (d) -> d.id == demo_id))
        obj
      , {})
      subject_object
    , {})

  addMinor = ->
    $translate(['NEW_MINOR_LBL']).then (t) ->
      subject = SubjectSvc.new(cid: guid(), name: t.NEW_MINOR_LBL, head_of_household: false, household: { id: currentUser.household_id })
      vm.minors.push(subject)
      # we amend rather than replace our rules on form state here
      # in order to avoid a prematurely triggering a hidden or read only state after a subject fills out a field with an after collection rule (e.g. after_collection read_only) and then hits "add minor"
      vm.read_only = _.extend(vm.read_only, buildReadOnlyFormState([subject]))
      data.hidden = _.extend(data.hidden, buildHiddenFormState([subject]))
      vm.subjects_category_selections = _.extend(vm.subjects_category_selections, buildSubjectCategorySelections([subject]))
    return

  removeSubject = (subject, form) ->
    if subject.id
      subject._destroy = true
      form.$setDirty()
    else
      vm.minors = _.without(vm.minors, subject)
    return

  exit_check = $rootScope.$on '$stateChangeStart', (event, toState, toParams, fromState, fromParams) ->
    if vm.minor_entry_form && vm.minor_entry_form.$dirty
      event.preventDefault()
      $translate(['CANCEL_BTN', 'UNSAVED_CHANGES_LBL', 'NOT_SAVED_MSG', 'CONTINUE_WITHOUT_SAVING_BTN']).then (t) ->
        $uibModal.open(
          templateUrl: "rj_shared/dialog.html"
          controller: "dialogCtrl"
          resolve:
            settings: -> {
              title: t.UNSAVED_CHANGES_LBL,
              message: t.NOT_SAVED_MSG,
              style: "danger",
              ok_button: t.CONTINUE_WITHOUT_SAVING_BTN,
              cancel_button: t.CANCEL_BTN
            }
        ).result.then (result) ->
          if result
            vm.minor_entry_form.$setPristine()
            $state.go(toState)

  $scope.$on '$destroy', ->
    exit_check()

  vm = {
    minor_entry_form: {}
    minors: []
    adults: []
    languages: []
    category_rules: []
    subjects_category_selections: {}
    read_only: {}
    countries: COUNTRIES
    show_all_fields: false
    state:
      errors: {}
    part_of_forced_entry: $stateParams.pass_through_sref?

    submit: submit
    cancel: cancel
    showFields: showFields
    showOptional: showOptional
    removeSubject: removeSubject
    addMinor: addMinor
    isMinor: isMinor
  }
  $scope.vm = vm

export default minorEntryCtrl
