import * as _ from "lodash"
import * as angular from "angular"

scheduleConfirmationCtrl = (
  $scope
  $q
  $stateParams
  $state
  $location
  $filter
  SubjectInteractionSessionSvc
  RecruitingCampaignSvc
  SubjectInteractionSvc
  SensoryTestSvc
  CompanySvc
  currentUser
  languageHelper
  moment
  appInfo
) ->
  "ngInject"

  promises = [
    RecruitingCampaignSvc.one($stateParams.recruiting_campaign_id).get()
    SubjectInteractionSessionSvc.all().getList({subject_interaction_id: $stateParams.subject_interaction_id})
    CompanySvc.one(currentUser.company_id).get()
    SubjectInteractionSvc.one($stateParams.subject_interaction_id).get()
    RecruitingCampaignSvc.meta($stateParams.recruiting_campaign_id)
  ]
  $q.all(promises).then (responses) ->
    vm.plan = responses[0]
    vm.subject_interaction_sessions = responses[1]
    vm.company = responses[2]
    vm.interaction = responses[3]
    interaction = vm.interaction
    vm.plan.meta = responses[4]

    temp = compactAndMerge(angular.copy(languageHelper), vm.company.meta.defaults.recruiting.scripts)
    vm.scripts = compactAndMerge(temp, vm.plan.meta.scripts)

    vm.variables =
      subject_name: "#{currentUser.first_name} #{currentUser.last_name}"
      company_name: vm.company.name
      event_start_date: moment(vm.plan.event_start_date).format('MMMM Do YYYY, h:mm a')
      event_end_date: moment(vm.plan.event_end_date).format('MMMM Do YYYY, h:mm a')
      event_title: vm.plan.event_name
      address: vm.plan.address || '{no address provided}'
      location_city: vm.plan.location_city || '{no location city provided}'
      location_name: vm.plan.location_name || '{no location name provided}'
      phone_number: vm.company.meta.defaults.recruiting.phone_number || '{no number provided}'
      compensation: vm.plan.event_compensation || '{no compensation amount provided}'
      recruiter_name: if interaction.user then interaction.user.full_name else 'None'
      session_date: if vm.subject_interaction_sessions[0] then $filter('preferredDate')(moment(vm.subject_interaction_sessions[0].session.start_time).tz(vm.plan.location_timezone_id), vm.plan) else ''
      session_time: if vm.subject_interaction_sessions[0] then $filter('preferredDateTime')(moment(vm.subject_interaction_sessions[0].session.start_time).tz(vm.plan.location_timezone_id), vm.plan) else ''
    SensoryTestSvc.meta(vm.plan.sensory_test_id).then (result) ->
      vm.locales = result.locales
      checkPreferredLanguage(interaction)

  checkPreferredLanguage = (interaction) ->
    if $location.search()['locale'] == undefined && interaction.subject.preferred_language && _.contains(vm.locales, interaction.subject.preferred_language)
      setLocale(interaction.subject.preferred_language)
    else
      setLocale($location.search()['locale'] || vm.locales[0])

  setLocale = (locale) ->
    $location.search(_.extend($location.search(), {locale: locale}))
    vm.current_locale = if Array.isArray(locale) then locale[0] else locale

  print = ->
    window.print()

  vm = {
    plan: null
    company: null
    languageHelper: languageHelper
    setLocale: setLocale
    print: print
    current_user: currentUser
    appInfo: appInfo
    interaction: null
  }
  $scope.vm = vm

export default scheduleConfirmationCtrl
