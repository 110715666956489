import * as _ from "lodash"
import * as angular from "angular"

screenerCtrl = (
  $scope
  $stateParams
  $q
  $state
  $location
  $rootScope
  SubjectInteractionSvc
  RecruitingCampaignSvc
  Restangular
  surveyHelper
) ->
  "ngInject"


  promises = [
    SubjectInteractionSvc.one($stateParams.subject_interaction_id).get()
    RecruitingCampaignSvc.one($stateParams.recruiting_campaign_id).get()
    SubjectInteractionSvc.one($stateParams.subject_interaction_id).customPOST({locale: $stateParams.locale}, 'in_progress_check')
  ]

  $q.all(promises).then (responses) ->
    vm.interaction = responses[0]
    vm.campaign = responses[1]
    data = responses[2].plain()
    checkIfInteractionIsRejected(vm.interaction)
    # setLocale(data.locale)
    checkPreferredLocale(data)
    surveyHelper.populateFromServer(data)
    surveyHelper.isBuilderPreview = false
    surveyHelper.addStyles(surveyHelper.questionnaire.config) if surveyHelper.questionnaire.config
    $rootScope.$broadcast "reloadTakerEvent", 0
    $scope.show_taker = true

  checkIfInteractionIsRejected = (interaction) ->
    if interaction.outcome == 'rejected'
      $state.go('qualify.exit', {recruiting_campaign_id: vm.campaign.id})

  checkPreferredLocale = (data) ->
    if vm.interaction.subject && vm.interaction.subject.preferred_language && _.contains(data.locales, vm.interaction.subject.preferred_language)
      setLocale(vm.interaction.subject.preferred_language)
    else
      setLocale(data.locale)

  setLocale = (locale) ->
    $location.search(_.extend($location.search(), {locale: locale}))
    vm.current_locale = if Array.isArray(locale) then locale[0] else locale

  $scope.$on "answerSelectedEvent", (event, data) ->
    Restangular.all("answer_submit_events").post({question_data: data})

  $scope.$on "questionnaireCompleteEvent", (event, data) ->
    demographic_ids = _.flatten _.map(data.demographic_categories, (demographic_ids, dc_id) -> _.map(demographic_ids, (d) -> parseInt(d)))
    cmd = {passed: data.passed, questionnaire_data: data.in_progress_questionnaire, demographic_ids: demographic_ids}

    # if screener resulted in termination, add the termination name
    cmd.termination_scenario = data.termination_scenario.name if data.termination_scenario

    vm.interaction.customPOST(cmd, 'questionnaire_submit').then (response) ->
      if response.qualifies
        if vm.campaign.disable_scheduling
          $state.go('qualify.screened', $stateParams)
        else
          if vm.campaign.location_type != 'online'
            $state.go('qualify.schedule', $stateParams)
          else
            $state.go('qualify.online_confirmation', $stateParams)
      else
        if response.redirect_url
          window.location = response.redirect_url
        else
          $state.go('qualify.exit', $stateParams)

  vm =
    interaction: null
    campaign: null
    surveyHelper: surveyHelper

  $scope.vm = vm

export default screenerCtrl

