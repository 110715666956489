import * as _ from "lodash"

campaignsListCtrl = (
  $q
  $scope
  $state
  $location
  $translate
  appInfo
  ngToast
  currentUser
  NgTableParams
  RecruitingCampaignSvc
  SubjectInteractionSvc
) ->
  "ngInject"

  current_subject = currentUser
  promises = []

  translations = {}
  $translate(['EVENT_LOCATION_LBL', 'EVENT_START_DATE_LBL', 'EVENT_END_DATE_LBL', 'CREATED_BY_LBL', 'STATUS_LBL', 'NAME_LBL', 'CAMPAIGN_LBL', 'SESSION_COUNT_LBL']).then (t) ->
    translations.event_location = t.EVENT_LOCATION_LBL
    translations.event_start_date = t.EVENT_START_DATE_LBL
    translations.event_end_date = t.EVENT_END_DATE_LBL
    translations.created_by = t.CREATED_BY_LBL
    translations.status = t.STATUS_LBL
    translations.name = t.NAME_LBL
    translations.campaign = t.CAMPAIGN_LBL
    translations.session_count = t.SESSION_COUNT_LBL

  filter_info = {}
  setStateFromParams = (search_params) ->
    filter_info.order_by = search_params.order_by || 'id'
    filter_info.order_dir = search_params.order_dir || 'desc'
    filter_info.page = search_params.page || 1
    filter_info.per = search_params.per || 10
    filter_info.search_term = search_params.search_term || ''
  setStateFromParams($location.search())

  table_params = new NgTableParams({
    page: filter_info.page
    count: filter_info.per
    sorting:
      "#{filter_info.order_by}": "#{filter_info.order_dir}"
  },
    filterDelay: 300
    getData: (params) ->
      filter_data =
        #search_term: filter_info.search_term
        page: params.page()
        per: params.count()
        order_by: params.orderBy()[0].substring(1)
        order_dir: if params.orderBy()[0].substring(0,1) == "+" then "asc" else "desc"
        subject_id: current_subject.id
        start_date: moment().local().startOf('day').local()
        end_date: moment().startOf('day').add(2, 'months').local()
        subjects_can_screen: true

      promises.push(RecruitingCampaignSvc.all().getList(filter_data))
      $q.all(promises).then (res) ->
        vm.campaigns = res[0]

        _.all vm.campaigns, (p) -> if p.name == null then p.name = p.event_name

        params.total res[0].meta.count
        $location.search(_.extend($location.search(),filter_data))
        vm.campaigns
      , (res) ->
        []
  )

  toQualification = (campaign) ->
    cmd = { recruiting_campaign_id: campaign.id, subject_id: current_subject.id }
    SubjectInteractionSvc.all().customPOST(cmd, 'subject_screen').then (response) ->
      if response.progress.screen == 'valid'
        $state.go('qualify.schedule', { subject_interaction_id: response.id, recruiting_campaign_id: campaign.id })
      else
        $state.go('qualify.screener', { subject_interaction_id: response.id, recruiting_campaign_id: campaign.id })
    , (response) ->
      ngToast.danger content: "Error creating interaction"


  #################### initial load section ########################


  vm = {
    translations: translations
    filter_info: filter_info
    table_params: table_params
    toQualification: toQualification
    current_user: currentUser
  }

  $scope.vm = vm

export default campaignsListCtrl

