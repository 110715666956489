const compactObject = function (o) {
  _.each(o, function (v, k) {
    if (_.isObject(v)) {
      return compactObject(v);
    } else {
      if (v == null || ((_.isObject(v) || _.isArray(v)) && _.isEmpty(v))) {
        delete o[k];
      }
      return;
    }
  });
  return o;
};
window.compactObject = compactObject;

const compactAndMerge = function (dest, src) {
  dest = compactObject(dest);
  src = compactObject(src);
  const res = _.merge(dest, src);
  return res;
};
window.compactAndMerge = compactAndMerge;

export default { compactObject, compactAndMerge };
