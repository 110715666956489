import * as _ from "lodash"

campaignsCalendarCtrl = (
  $q
  $scope
  $location
  appInfo
  currentUser
  ngToast
  RecruitingCampaignSvc
  SubjectInteractionSvc
) ->
  "ngInject"

  promises = []

  current_subject = currentUser
  action_links = '<ul class="actions actions-alt" id="fc-actions">' + '<li class="uib-dropdown" uib-dropdown>' + '<a href="" uib-dropdown-toggle><i class="zmdi zmdi-more-vert"></i></a>' + '<ul class="uib-dropdown-menu dropdown-menu dropdown-menu-right">' + '<li class="active">' + '<a data-calendar-view="month" href="">Month View</a>' + '</li>' + '<li>' + '<a data-calendar-view="basicWeek" href="">Week View</a>' + '</li>' + '</ul>' + '</div>' + '</li>'

  calendar_config = {
    lang: currentUser.preferred_language,
    theme: true,
    header: {
      right: '',
      center: 'prev, title, next',
      left: ''
    }
  }

  fetchCampaignsForCalendar = (start, end, timezone, callback) ->
    events = []
    filter_data =
        subject_id: current_subject.id
        start_date: moment().local().startOf('day').local()
        end_date: moment().startOf('day').add(2, 'months').local()
        include_all: true
    promises.push(RecruitingCampaignSvc.all().getList(filter_data))
    promises.push(SubjectInteractionSvc.all().customPOST({subject_id: current_subject.id, per: 20}, 'search'))
    $q.all(promises).then (result) ->
      vm.campaigns = result[0]
      interactions = result[1]
      _.each interactions, (i) ->
        if i.outcome == 'rejected'
          _.remove(vm.campaigns, (p) ->
            p.id == i.recruiting_campaign_id
        )

      _.each vm.campaigns, (campaign) ->
        if campaign.name == null
            campaign.name = campaign.event_name
        if campaign.event_start_date
          events.push({
            backgroundColor: '#587291',
            title: "#{campaign.project_name}: \n #{campaign.event_name}",
            start: campaign.event_start_date,
            end: campaign.event_end_date
          })
      callback events


  #################### initial load section ########################

  vm = {
    action_links: action_links
    calendar_config: calendar_config
    fetchCampaignsForCalendar: fetchCampaignsForCalendar
  }

  $scope.vm = vm

export default campaignsCalendarCtrl

