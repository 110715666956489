var portalThemeLogo

portalThemeLogo = function () {
  "ngInject"
  return {
    restrict: "E",
    replace: true,
    scope: {},
    controller: [
      "$scope",
      "currentUser",
      "$sce",
      function ($scope, currentUser, $sce) {
        $scope.trustedTemplate = $sce.trustAsHtml(currentUser.theme?.portal_logo_template)

        // $scope.trustedTemplate = $sce.trustAsHtml(`
        // <a href="/">
        // <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        // viewBox="0 0 689.4 159.5" style="width:170px; margin: 4px 0 0 8px !important;" xml:space="preserve">
        // <g>
        // <path d="M0,2.1v155.3h64.2V79.8V2.1L0,2.1L0,2.1z"/>
        // <path d="M140.2,79.8l18.7-27.9c4.7-7,7-13.3,7-21.2c0-16.3-13.1-30.6-31.7-30.6c-12.9,0-22.2,6.1-30.2,18.5L64.2,79.8L104,141
        // c8,12.4,17.3,18.5,30.2,18.5c18.6,0,31.7-14.3,31.7-30.6c0-7.9-2.3-14.2-7-21.2L140.2,79.8z"/>
        // <path d="M331.6,40.6c-22.4,0-38.6,18-38.6,38.4c0,20.9,15.3,38.2,38.5,38.2c17.4,0,30.5-9.9,36.4-23.2h-18.6
        // c-3.4,5.1-9,8.2-17.5,8.2c-12,0-19.7-6.6-21.9-16.9h59.2c0.3-2,0.5-3.9,0.5-6.5C369.6,59.4,355.5,40.6,331.6,40.6z M331.5,55.4
        // c12.1,0,19.2,6.6,21.2,16.6H310C312.4,61.9,320.3,55.4,331.5,55.4L331.5,55.4z"/>
        // <path d="M293.7,42.5v-0.9h-20.6L239.4,78l34,38.3h22v-0.9l-34.3-37.9L293.7,42.5z"/>
        // <path d="M222.4,41.6v74.7h17V77.9V41.5L222.4,41.6L222.4,41.6z"/>
        // <path d="M689.4,78.7c0-19.3-14.1-38.1-38.1-38.1c-22.4,0-38.6,18-38.6,38.4c0,20.9,15.3,38.2,38.5,38.2c17.4,0,30.5-9.9,36.4-23.2
        // h-18.6c-3.4,5.1-9,8.2-17.5,8.2c-12,0-19.7-6.6-21.9-16.9h59.2C689.2,83.2,689.4,81.3,689.4,78.7z M651.3,55.4
        // c12.1,0,19.3,6.6,21.2,16.6h-42.7C632.2,61.9,640.1,55.4,651.3,55.4z"/>
        // <path d="M585.1,84c0,10.2-6.4,17.3-17.2,17.3c-10.7,0-17-7.1-17-17.3V41.5h-17V85c0,18.2,13,32.1,33.6,32.1
        // c21,0,34.6-14.8,34.6-32.1V41.5h-17V84z"/>
        // <path d="M414.7,40.6c-20.5,0-34.8,15.1-34.8,32.5v43.1h17V74.1c0-10.5,6.4-17.6,17.4-17.6s17.3,7.1,17.3,17.6v42.1h17V73.1
        // C448.7,55,436.3,40.6,414.7,40.6z"/>
        // <path d="M488.9,84.6l-21-43.1h-18.2l38.5,76.4h0.8l38.4-76.4h-17.5L488.9,84.6z"/>
        // </g>
        // </svg>
        // </a>
        // `);
      },
    ],
    template: '<span class="portal-theme-logo" ng-bind-html="trustedTemplate"></span>',
  }
}

export default portalThemeLogo
