import cookies from "~angular/src/rj_shared/utils/cookies"
import urls from "~angular/src/rj_shared/utils/urls"

config = (
  $translateProvider
  $locationProvider
  uiSelectConfig
  $compileProvider
  $uibModalProvider
  cfpLoadingBarProvider
  RestangularProvider
) ->
  "ngInject"

  $locationProvider.html5Mode(true)
  $translateProvider.useLoader('translationLoader')
  if window.current_user
    $translateProvider.preferredLanguage(window.current_user.preferred_language || 'en_US')
  else
    $translateProvider.fallbackLanguage('en_US')
    $translateProvider.preferredLanguage('en_US')
    $translateProvider.useSanitizeValueStrategy(null)
    $translateProvider.forceAsyncReload(true)

  uiSelectConfig.theme = 'bootstrap'

  $uibModalProvider.options = { backdrop: 'static', keyboard: true, windowClass: 'animated-fast zoomIn' }

  cfpLoadingBarProvider.includeBar = false
  RestangularProvider.setDefaultHeaders
    'Content-Type': 'application/json'
    'Authorization': 'Bearer ' + cookies.getCookie('portal_auth')

  oldWhiteList = $compileProvider.imgSrcSanitizationWhitelist()
  $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|file|blob):|data:image\//)

export { config }
