import * as angular from "angular"


profile = angular.module('redjade.portal.ui.profile', [

])
  .controller('subjectProfilePicCtrl', require("./subjectProfilePic.controller").default)
  .controller('subjectsMyScheduleCtrl', require("./subjectsMySchedule.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/profile/about.html', require("./about.html"))
    $templateCache.put('ui/profile/confirmation.html', require("./confirmation.html"))
    $templateCache.put('ui/profile/contact.html', require("./contact.html"))
    $templateCache.put('ui/profile/onboarding_survey.html', require("./onboarding_survey.html"))
    $templateCache.put('ui/profile/profile.html', require("./profile.html"))
    $templateCache.put('ui/profile/schedule.html', require("./schedule.html"))
    $templateCache.put('ui/profile/subject_field_form.html', require("./subject_field_form.html"))
    $templateCache.put('ui/profile/thank_you.html', require("./thank_you.html"))
  )


export default profile
