routes = (
  $urlRouterProvider
  $stateProvider
) ->
  "ngInject"

  router = (Restangular, currentUser, $state, $stateParams, $timeout, current_state) ->
    cmd = if $stateParams.subject_interaction_id then { subject_interaction_id: $stateParams.subject_interaction_id } else {}
    Restangular.one("subjects", currentUser.id).customGET("progress", cmd).then (progress) ->
      params = $stateParams
      if !$stateParams.pass_through_sref && _.any(_.values(progress), (val) -> val == 'invalid')
        params = $.extend(params, { pass_through_sref: current_state })
      currentUser.progress = progress

      switch progress.prioritize_screening
        when 'consent_only'
          if progress.consent == 'invalid'
            $state.go('consent', params)
          return
        when 'skip_everything'
          return

      if progress.restricted || !progress.has_login_account
        window.navigating = true
        window.location = "/login"
        return
      else if progress.consent == 'invalid'
        $state.go('consent', params)
      else if progress.profile == 'invalid'
        params = $.extend(params, { part_of_subject_interaction: 'true' })
        $state.go('profile_confirmation', params)
      else if progress.interests == 'invalid'
        $state.go('forced_interest_selection', params)
      else if progress.onboarding == 'invalid'
        $state.go('onboarding_survey', params)
      else if progress.minor_entry == 'invalid'
        $state.go('forced_minor_entry', params)
      else if currentUser.is_minor && _.includes(current_state, 'minor_entry')
        $state.go('profile.about')
      return
    return

  $stateProvider.state "consent",
    url: "/consent?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id"
    controller: "consentCtrl"
    templateUrl: "ui/subject_consent/consent.html"
    reloadOnSearch: false
    resolve:
      current_subject: (currentUser) ->
        "ngInject"
        currentUser
      user_is_subject: ->
        true
      failure_state: ->
        "consent_declined"
      recruiting_campaign: -> null # for call information
      subject_interaction: -> null # for call information

  $stateProvider.state "consent_declined",
    url: "/consent_declined?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id"
    controller: "consentFailCtrl"
    templateUrl: "rj_shared/consent_declined.html"
    resolve:
      current_subject: (currentUser) ->
        "ngInject"
        currentUser
      user_is_subject: ->
        true
      consent_state: ->
        "consent"
      exit_state: ->
        null


  $stateProvider.state "profile_confirmation",
    url: "/profile_confirmation?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id:part_of_subject_interaction"
    controller: "subjectEditCtrl"
    templateUrl: "ui/profile/confirmation.html"
    resolve:
      current_subject: (currentUser) ->
        "ngInject"
        currentUser
      show_household: -> false

  $stateProvider.state "onboarding_survey",
    url: "/onboarding?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id:demographic_category_id:onboarding_configuration_id"
    controller: "onboardingSurveyCtrl"
    templateUrl: "ui/profile/onboarding_survey.html"

  $stateProvider.state "onboarding_thank_you",
    url: "/thankyou?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id"
    controller: "onboardingThankYouCtrl"
    templateUrl: "ui/profile/thank_you.html"


  $stateProvider.state "profile",
    url: "/profile?:pass_through_sref:locale"
    abstract: true
    views:
      "@":
        templateUrl: "ui/profile/profile.html"

  $stateProvider.state "minor_entry",
    url: "/minors?:pass_through_sref:locale"
    controller: "minorEntryCtrl"
    templateUrl: "ui/household/unforced_minor_entry.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "minor_entry")

  $stateProvider.state "forced_minor_entry",
    url: "/minor_entry?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id"
    controller: "minorEntryCtrl"
    templateUrl: "ui/household/forced_minor_entry.html"

  $stateProvider.state "just_profile",
    url: "/just_profile?:pass_through_sref:locale"
    templateUrl: "ui/profile/about.html"
    controller: "subjectEditCtrl"
    resolve:
      current_subject: (currentUser) ->
        "ngInject"
        currentUser
      show_household: -> true
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "just_profile")

  $stateProvider.state "just_contact",
    url: "/just_contact?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id"
    templateUrl: "ui/profile/contact.html"
    controller: "consentCtrl"
    resolve:
      current_subject: (currentUser) ->
        "ngInject"
        currentUser
      user_is_subject: ->
        true
      failure_state: ->
        null
      recruiting_campaign: -> null # for call information
      subject_interaction: -> null # for call information
      # resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
      #   router(Restangular, currentUser, $state, $stateParams, $timeout, "just_contact")

  $stateProvider.state "profile.about",
    url: "/about"
    controller: "subjectEditCtrl"
    templateUrl: "ui/profile/about.html"
    resolve:
      current_subject: (currentUser) ->
        "ngInject"
        currentUser
      show_household: -> true
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "profile.about")

  $stateProvider.state "profile.contact",
    url: "/contact"
    controller: "consentCtrl"
    templateUrl: "ui/profile/contact.html"
    resolve:
      current_subject: (currentUser) ->
        currentUser
      user_is_subject: ->
        true
      failure_state: ->
        null
      recruiting_campaign: -> null # for call information
      subject_interaction: -> null # for call information
      #resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
      #  router(Restangular, currentUser, $state, $stateParams, $timeout, "profile.contact")

  $stateProvider.state "upcoming",
    url: "/upcoming?:pass_through_sref:locale"
    controller: "campaignsListCtrl"
    templateUrl: "ui/campaigns/list.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "upcoming")
    permissions: 'portal_access'

  $stateProvider.state "onboarding",
    url: "/interests?:pass_through_sref:locale"
    controller: "onboardingCtrl"
    templateUrl: "ui/onboarding/onboarding.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "onboarding")
    permissions: 'portal_access'

  $stateProvider.state "onboarding.interest_selection",
    url: "/interest_selection?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id"
    views:
      "@":
        controller: "interestSelectionCtrl"
        templateUrl: "ui/onboarding/interest_selection.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "onboarding.interest_selection")
    permissions: 'portal_access'

  $stateProvider.state "forced_interest_selection",
    url: "/interest_selection?:pass_through_sref:locale:subject_interaction_id:recruiting_campaign_id"
    controller: "interestSelectionCtrl"
    templateUrl: "ui/onboarding/forced_interest_selection.html"

  $stateProvider.state "schedule",
    url: "/schedule?:pass_through_sref:locale"
    controller: "subjectsMyScheduleCtrl"
    templateUrl: "ui/profile/schedule.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "schedule")
    permissions: 'portal_access'


  $stateProvider.state "qualify",
    abstract: true
    url: "/qualify/:recruiting_campaign_id?:pass_through_sref:locale"
    resolve:
      current_campaign: (RecruitingCampaignSvc, $stateParams) ->
        "ngInject"
        RecruitingCampaignSvc.one($stateParams.recruiting_campaign_id).get()

  $stateProvider.state "qualify.exit",
    url: "/interaction/:subject_interaction_id/exit?:pass_through_sref:locale"
    reloadOnSearch: false
    views:
      "@":
        templateUrl: "ui/qualify/terminate.html"
        controller: "terminateCtrl"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "qualify.exit")
      current_subject_interaction: ($stateParams, SubjectInteractionSvc) ->
        "ngInject"
        SubjectInteractionSvc.one($stateParams.subject_interaction_id).get({cache:new Date().getTime()})

  $stateProvider.state "qualify.screener",
    url: "/interaction/:subject_interaction_id/screener?:pass_through_sref:locale"
    reloadOnSearch: false
    views:
      "@":
        controller: "screenerCtrl"
        templateUrl: "ui/qualify/screener.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "qualify.screener")
      current_subject_interaction: ($stateParams, SubjectInteractionSvc) ->
        "ngInject"
        SubjectInteractionSvc.one($stateParams.subject_interaction_id).get({cache:new Date().getTime()})

  $stateProvider.state "qualify.screened",
    url: "/interaction/:subject_interaction_id/screened?:pass_through_sref:locale"
    reloadOnSearch: false
    views:
      "@":
        controller: "screenedCtrl"
        templateUrl: "ui/qualify/screened.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "qualify.screened")
      current_subject_interaction: ($stateParams, SubjectInteractionSvc) ->
        "ngInject"
        SubjectInteractionSvc.one($stateParams.subject_interaction_id).get({cache:new Date().getTime()})

  $stateProvider.state "qualify.schedule",
    url: "/interaction/:subject_interaction_id/schedule?:pass_through_sref:locale"
    reloadOnSearch: false
    views:
      "@":
        controller: "scheduleCtrl"
        templateUrl: "ui/qualify/schedule.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "qualify.schedule")
      current_subject_interaction: ($stateParams, SubjectInteractionSvc) ->
        "ngInject"
        SubjectInteractionSvc.one($stateParams.subject_interaction_id).get({cache:new Date().getTime()})

  $stateProvider.state "qualify.schedule_confirmation",
    url: "/interaction/:subject_interaction_id/schedule_confirmation?:pass_through_sref:locale"
    reloadOnSearch: false
    views:
      "@":
        controller: "scheduleConfirmationCtrl"
        templateUrl: "ui/qualify/schedule_confirmation.html"
    resolve:
      resolveRoute: (Restangular, currentUser, $state, $stateParams, $timeout) ->
        "ngInject"
        router(Restangular, currentUser, $state, $stateParams, $timeout, "qualify.schedule_confirmation")
      current_subject_interaction: ($stateParams, SubjectInteractionSvc) ->
        "ngInject"
        SubjectInteractionSvc.one($stateParams.subject_interaction_id).get({cache:new Date().getTime()})

  $stateProvider.state "qualify.online_confirmation",
    url: "/interaction/:subject_interaction_id/online_confirmation?:pass_through_sref:locale"
    reloadOnSearch: false
    views:
      "@":
        controller: "onlineConfirmationCtrl"
        templateUrl: "ui/qualify/online_confirmation.html"


   $urlRouterProvider.otherwise "/profile/about"


export { routes }
