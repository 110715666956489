import '@neos21/bootstrap3-glyphicons'
import '/node_modules/font-awesome/css/font-awesome.min.css'
import '/node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.min.css'

# require("font-awesome")

import './stylesheets/fullcalendar.css'

import './stylesheets/vendor/_normalize.css'
import './stylesheets/vendor/animate.css'
import './stylesheets/vendor/animations.css'
import './stylesheets/vendor/bootstrap.css' #require("bootstrap/dist/css/bootstrap.css")
import './stylesheets/vendor/categorize_this.css'

import './fonts/roboto/font.css'
import './fonts/oswald/font.css'

import 'ng-toast/dist/ngToast.css'
import 'ng-toast/dist/ngToast-animations.css'

import 'angularjs-bootstrap-datetimepicker/src/css/datetimepicker.css'
import 'ui-select/dist/select.min.css'

require("trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css")
table_css = require("trumbowyg/dist/plugins/table/ui/trumbowyg.table.css")
require("trumbowyg/dist/ui/trumbowyg.css")

# SASS
import './stylesheets/common.css'
import './stylesheets/kendo-overrides.sass'
import './stylesheets/overrides.sass'
import './stylesheets/print_preview.sass'
import './stylesheets/theme.sass'
import './stylesheets/theme/actions.sass'
import './stylesheets/theme/alerts.sass'
import './stylesheets/theme/animations.sass'
import './stylesheets/theme/badges.sass'
import './stylesheets/theme/base.sass'
import './stylesheets/theme/breadcrumbs.sass'
import './stylesheets/theme/buttons.sass'
import './stylesheets/theme/calendar.sass'
import './stylesheets/theme/cards.sass'
import './stylesheets/theme/chat.sass'
import './stylesheets/theme/clearfix.sass'
import './stylesheets/theme/colorpicker.sass'
import './stylesheets/theme/consent.sass'
import './stylesheets/theme/colors.sass'
import './stylesheets/theme/d3.sass'
import './stylesheets/theme/datepicker.sass'
import './stylesheets/theme/depth.sass'
import './stylesheets/theme/dropdown_menu.sass'
import './stylesheets/theme/forms.sass'
import './stylesheets/theme/global_alerts.sass'
import './stylesheets/theme/imports.sass'
import './stylesheets/theme/layout.sass'
import './stylesheets/theme/list_view.sass'
import './stylesheets/theme/loading_bar.sass'
import './stylesheets/theme/menu.sass'
import './stylesheets/theme/messages.sass'
import './stylesheets/theme/modals.sass'
import './stylesheets/theme/ng_table.sass'
import './stylesheets/theme/ng_table_overrides.sass'
import './stylesheets/theme/notifications.sass'
import './stylesheets/theme/pagination.sass'
import './stylesheets/theme/panels.sass'
import './stylesheets/theme/participant_list.sass'
import './stylesheets/theme/popovers.sass'
import './stylesheets/theme/portlets.sass'
import './stylesheets/theme/profile.sass'
import './stylesheets/theme/ribbons.sass'
import './stylesheets/theme/scrollbars.sass'
import './stylesheets/theme/search_input.sass'
import './stylesheets/theme/sidebar.sass'
import './stylesheets/theme/sweet_alert.sass'
import './stylesheets/theme/table.sass'
import './stylesheets/theme/tabs.sass'
import './stylesheets/theme/tags.sass'
import './stylesheets/theme/tiles.sass'
import './stylesheets/theme/timeline.sass'
import './stylesheets/theme/toasts.sass'
import './stylesheets/theme/top_nav.sass'
import './stylesheets/theme/visuallyhidden.sass'
import './stylesheets/theme/widget.sass'
import './stylesheets/theme/wizard.sass'
import './stylesheets/theme/wysiwyg_colorpicker.sass'
