import * as angular from "angular"

# SASS
import './call.sass'

shared = angular.module('redjade.stylesheets.shared', [

])





export default shared
