import * as jquery from "jquery" // @ts-ignore
window.$ = window.jquery = window.jQuery = jquery
import "lodash-aliases"
import "angular-moment"
import "fullcalendar"
import "jquery-ui/ui/widgets/sortable"
import "jquery-ui/ui/widgets/draggable"
import "jquery-ui/ui/widgets/droppable"
import "ng-toast"

import "./utils/jquery.ui.touch-punch.js"
import * as angular from "angular"
import "angular-scroll"
import "angular-drag-and-drop-lists"
import "angular-dynamic-locale"
import "angular-ui-mask"
import "angular-clipboard"
import "angular-filter"
import "angular-ui-sortable"
import "angular-loading-bar"
import "angular-touch"
import "angular-animate"
import "angular-dragdrop"
import "angular-ui-router"
import "ui-router-extras"
import "angular-ui-bootstrap"
import "angular-sanitize"
import "angular-local-storage"
import "restangular"
import "ng-table"
import "ui-select"
import "angular-file-upload"
import "angularjs-bootstrap-datetimepicker"
import "angular-ui-calendar"
import * as moment_timezone from "moment-timezone"
import "nouislider"
// import "nouislider/src/nouislider.less"
import "nouislider/distribute/nouislider.css"

import "ng-table/bundles/ng-table"

import "angular-elastic-input"
import "angular-translate"
import "angular-translate-loader-static-files"
import "angular-chart.js"

import { run } from "./init/run"
import { config } from "./init/config"
import "./init/pusher_config"

import "./utils/string_helpers"

import "./consent/index"
import "./constants/index"
import "./models/index"
import "./services/index"
import "./directives/index"
import "./filters/index"
import "./scheduling_taker/index"
import "./ui/participants/index"
import "./ui/sensory_tests/index"
import "./ui/utils/index"
import "./ui/link_shares/index"

import templates from "./templates/index"

const mod = angular
  .module("redjade.shared", [
    "restangular",
    "ngTable",
    "ngToast",
    "angularMoment",
    "duScroll",
    "ui.select",
    "dndLists",
    "pascalprecht.translate",
    "tmh.dynamicLocale",
    "ui.mask",
    "angular-clipboard",
    "angular-loading-bar",
    "angular.filter",
    "ui.bootstrap",
    "ui.bootstrap.datetimepicker",
    "ui.sortable",
    "ngAnimate",
    "ui.router",
    "ct.ui.router.extras",
    "ngSanitize",
    "LocalStorageModule",
    "angularFileUpload",
    "ui.calendar",
    "ngDragDrop",
    "chart.js",
    "redjade.consent",
    "redjade.constants",
    "redjade.filters",
    "redjade.ui.participants",
    "redjade.scheduling_taker",
    "redjade.ui.sensory_tests",
    "redjade.ui.utils",
    "redjade.ui.link_shares",
    "redjade.services",
    "redjade.models",
    "redjade.directives",
    templates.name,
  ])
  .constant("moment", moment_timezone)
  .run(run)
  .config(config)

export default mod
