import shared from '~angular/src/rj_shared/index'
import * as angular from "angular"


import survey_taker from '~angular/src/survey_taker/index'
import consumer_subjects from '~angular/src/consumer_subjects/index'

import '~angular/src/rj_shared_stylesheets/index'
import 'angular-clipboard'
import '~angular/src/../vendor/javascripts/uiBreadcrumbs.js'

import directives from './directives/index'
import './stylesheets/index'

import ui_campaigns from './ui/campaigns/index'
import ui_household from './ui/household/index'
import ui_nav from './ui/nav/index'
import ui_onboarding from './ui/onboarding/index'
import ui_profile from './ui/profile/index'
import ui_qualify from './ui/qualify/index'


portal = angular.module('redjade.portal', [
  'angularUtils.directives.uiBreadcrumbs'
  shared.name
  survey_taker.name
  consumer_subjects.name

  ui_nav.name
  directives.name

  ui_profile.name
  ui_campaigns.name
  ui_household.name
  ui_onboarding.name
  ui_qualify.name
])

import { config } from "./app.config"
import { routes } from "./app.routes"
app_config = {
  name: 'Portal',
  api_version: 'v1',
  app_id: 'access_portal',
  app_module_name: portal.name
}
portal
  .constant('APP_CONFIG', app_config)
  .config(config)
  .config(routes)

if(module.hot)
  module.hot.accept()

import appbootstrap from './app.bootstrap'
appbootstrap(app_config)


export default portal
