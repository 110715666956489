import * as angular from "angular"


onboarding = angular.module('redjade.portal.ui.onboarding', [

])
  .controller('interestSelectionCtrl', require("./interestSelection.controller").default)
  .controller('onboardingCtrl', require("./onboarding.controller").default)
  .controller('onboardingSurveyCtrl', require("./onboardingSurvey.controller").default)
  .controller('onboardingThankYouCtrl', require("./onboardingThankYou.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/onboarding/forced_interest_selection.html', require("./forced_interest_selection.html"))
    $templateCache.put('ui/onboarding/interest_selection.html', require("./interest_selection.html"))
    $templateCache.put('ui/onboarding/interest_selection_form.html', require("./interest_selection_form.html"))
    $templateCache.put('ui/onboarding/onboarding.html', require("./onboarding.html"))
  )




export default onboarding
