import * as angular from "angular"


household = angular.module('redjade.portal.ui.household', [

])
  .controller('minorEntryCtrl', require("./minorEntry.controller").default)
  .run(($templateCache) ->
    "ngInject"
    # HTML
    $templateCache.put('ui/household/forced_minor_entry.html', require("./forced_minor_entry.html"))
    $templateCache.put('ui/household/minor_entry.html', require("./minor_entry.html"))
    $templateCache.put('ui/household/unforced_minor_entry.html', require("./unforced_minor_entry.html"))
  )




export default household
