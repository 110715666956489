import * as _ from "lodash"

interestSelectionCtrl = (
  $q
  $scope
  $stateParams
  $state
  ngToast
  DemographicCategorySvc
  SubjectSvc
  currentUser
) ->
  "ngInject"


  $q.all([
    DemographicCategorySvc.onboarding().getList(subject_id: currentUser.id)
    SubjectSvc.one(currentUser.id).customGET("subject_interests")
  ]).then (results) ->
    vm.categories = results[0]
    vm.selections = _.reduce(vm.categories, (selections, category) ->
      selections[category.id] = _.any(results[1], (dc) -> dc.id == category.id)
      selections
    , {})

  submit = (form) ->
    selections = _(vm.selections).map((val, category_id) -> if val then category_id else null).compact().value()
    SubjectSvc.one(currentUser.id).customPOST({ demographic_category_ids: selections }, "subject_interests/set_interests_inclusive").then (result) ->
      currentUser.interests_selected_at = new Date()
      currentUser.progress.interest_selection_necessary = false
      if $stateParams.pass_through_sref
        $state.go($stateParams.pass_through_sref, $stateParams)
      else
        $state.go('onboarding', $stateParams, { reload: true })

  cancel = (form) ->
    form.$setPristine()

  showCancel = (form) ->
    form.$dirty && !vm.part_of_subject_interaction && currentUser.interests_selected_at

  vm = {
    categories: []
    selections: {}
    currentUser: currentUser
    errors: {}
    part_of_subject_interaction: $stateParams.subject_interaction_id?

    submit: submit
    cancel: cancel
    showCancel: showCancel
  }
  $scope.vm = vm

export default interestSelectionCtrl

