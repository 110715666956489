SessionExpirationWarningCtrl = (
  $scope
  currentUser
  Restangular
  $uibModalInstance
) ->
  "ngInject"

  extendSession = () ->
    $uibModalInstance.close(true) #the callback that updates the session is on the modal's closed event

  vm = {
    extendSession: extendSession
  }
  $scope.vm = vm

export default SessionExpirationWarningCtrl
