portalTopNav = (
) ->
  "ngInject"

  return {
    restrict: 'E',
    scope: {},
    controller: 'portalTopNavCtrl',
    templateUrl: 'directives/portal_top_nav.html'
  }

export default portalTopNav
